.turnjs-slider{
	width:600px;
	height:8px;
	background:rgba(0,0,0, 0.2);
	margin:60px auto;
}

.turnjs-slider .thumbnail{
	width:115px;
	height:85px;
	position:absolute;
	background:rgba(0,0,0, 0.5);
	z-index:10000;
	top:-100px;
	left:-17px;
	display:none;

	-webkit-transform:translate3d(0px, 50px, 0px) scale(0.1, 0.1);
	-webkit-transition:-webkit-transform 0.2s;
	-webkit-border-radius:5px;

	-moz-transform:translate3d(0px, 50px, 0px) scale(0.1, 0.1);
	-moz-transition:-moz-transform 0.2s;
	-moz-border-radius:5px;

	-o-transform:translate3d(0px, 50px, 0px) scale(0.1, 0.1);
	-o-transition:-o-transform 0.2s;
	-o-border-radius:5px;

	-ms-transform:translate3d(0px, 50px, 0px) scale(0.1, 0.1);
	-ms-transition:-ms-transform 0.2s;
	-ms-border-radius:5px;

	transform:translate3d(0px, 50px, 0px) scale(0.1, 0.1);
	transition:transform 0.2s;
	border-radius:5px;
}

.no-transition{
	-webkit-transition:none;
	-moz-transition:none;
	-o-transition:none;
	-ms-transition:none;
}

.turnjs-slider .thumbnail div{
	width:100px;
	margin:7px;
	height:70px;
	background-color:white;
}

.turnjs-slider .ui-state-hover .thumbnail{
	display:block;
	opacity:0;
}

.turnjs-slider .ui-state-active .thumbnail{
	display:block;
	opacity:1;
	-webkit-transform:scale(1, 1);
	-moz-transform:scale(1, 1);
	-o-transform:scale(1, 1);
	-ms-transform:scale(1, 1);
	transform:scale(1, 1);
}

/* Layout helpers
----------------------------------*/

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
	cursor: default !important;
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.turnjs-slider .ui-widget-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.turnjs-slider .ui-slider {
	position: relative;
	text-align: left;
}

.turnjs-slider .ui-slider-handle{
	position: absolute;
	z-index:0;
	width:80px;
	height: 7px;
	cursor: default;

}

.turnjs-slider .ui-slider-horizontal {
	height: 8px;
	width:520px;
	margin-left:39px;
}

.turnjs-slider .ui-slider-horizontal .ui-slider-handle {
	margin-left:-40px;
	margin-top:-1px;
	background: #0bb8e9;
	opacity: 1;
	position: relative;
}
.now_page{    position: absolute;left: 53px;top: -21px;z-index: 50000;}
.go_start{float: left;padding-left: 215px;padding-top: 53px}
.go_finish{float: right;margin-top: -76px;margin-right: 215px;}
/* Interaction states
----------------------------------*/
.turnjs-slider .ui-slider-handle{
	border:1px solid white;
	background-color:black;
	opacity:0.2;
	display:block;
	-webkit-transition:opacity 0.2s;
	-moz-transition:opacity 0.2s;
	-ms-transition:opacity 0.2s;
	-o-transition:opacity 0.2s;
	transition:opacity 0.2s;
}

.turnjs-slider .ui-state-hover{
	opacity:0.4;
}

.turnjs-slider .ui-state-active{
	opacity:1;
}

